.homeAndFooter{
    display: flex;
    flex-direction: column;
}

.home{
    display:flex;
    justify-content: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

/* for centering check */
/* .center-line{
    background-color: black;
    width: 2px;
    height: 100%;
    z-index:1000;
    position:absolute;
    padding-left:50%-1px;
} */
.tab-bar{
    display: flex;
    flex-direction: column;
    top:0;
    z-index:10;
    min-width: 100%;
    /* min-height: 100vh; */
    position: sticky;
}
@media screen and (min-width: 0px) {
    .tab-label-text{
        display: none;
    }
}
@media screen and (min-width: 398px) {
    .tab-label-text{
        display: inline;
    }
}

.tabs .ant-tabs-nav{
    background-color:white;
    border-bottom: 1px solid gray ;
    position:sticky;
    top: 0;
    justify-content: flex-start;
}

.tab-label {
    font-family: Comfortaa;
    padding:5px;
    margin-left:5px;
    margin-right:5px;
}

.ant-tabs-content-holder{
    z-index: -1;
}

#activePage{
    position:relative;
    overflow-x: hidden;
    /* min-height:100vh; */
}

.pageWrapper{
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%; /* makes each page only as long as its content vs. as long as the longest page's content */
    transition: transform 400ms ease;
}

.pageWrapper[data-status="active"]{
    /* visibility: visible; */
    transform: translate(0%);
    position:relative;
}

.pageWrapper[data-status="inactiveLeft"]{

    transform: translate(-100vw);
    /* display: none; */
    /* max-height: min-content; */
    /* height:1px; /* makes each page only as long as its content vs as long as the longest page's content */
}

.pageWrapper[data-status="inactiveRight"]{
    /* animation-name: "swipe-right";
    animation-duration: 500ms;
    animation-timing-function: ease; */

    transform: translate(100vw);
    /* display: none; */
    /* max-height: min-content; */
    overflow-y: hidden; /* WARNING: comment out and pages don't blink out, but leave in and all page lengths become longest page's length */
}

/* @keyframes swipe-right {
    0% {translate: 0%}
    100% {translate: 100%; display: none;}
} */

.footer{
    z-index: 10;
    position: sticky;
    /* margin-top: 50px; */
    left:0;
    bottom:0;
    width:100vw;
    height: 47.6px; /* 47.6px /* for some reason displays as 25 px unless height > 52px*/
    background-color: #0088FF;
    transition: transform 400ms ease,
                box-shadow 0.2s, 
                border-color 0.2s, 
}

.linkedinIcon, .emailIcon, .githubIcon {
    color: white;
    transform: 200ms;
}

.githubIcon:hover {
    box-shadow: 0 0 20px 5px #ffffff63;
    border-radius: 100%;

    transition: 100ms;
}

.linkedinIcon:hover, .emailIcon:hover{
    box-shadow: 0 0 20px 5px #ffffff63;
    border-radius: 20%;

    transition: 100ms;
}

.center-line-check{ 
    /* I was just paranoid about a div being centered */
    position: fixed;
    background-color: fuchsia;
    width:2px;
    height:100vh;
    top:0;
    left:50vw;
    z-index: 100;
}