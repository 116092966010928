.resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size:large;
    padding-bottom:2%;
    font-family: Comfortaa;
    /* overflow-y: hidden; */
}
@media screen and (min-width: 0px) {
    .iframe-wrapper{
        height:1000px;
    }
    #resumeDoc{
        border:none;
        overflow-y: hidden;
        left: 0px; top: 0px; height: 1300px; width: 826px;
        box-shadow: 0 0 20px 3px rgba(90, 90, 90, 0.363);

        -ms-transform: scale(0.375);
        -moz-transform: scale(0.375);
        -o-transform: scale(0.375);
        -webkit-transform: scale(0.375);
        transform: scale(0.375);

        -ms-transform-origin: top center;
        -moz-transform-origin: top center;
        -o-transform-origin: top center;
        -webkit-transform-origin: top center;
        transform-origin: top center;
    }
}
@media screen and (min-width: 1024px) {
    #resumeDoc{
        border:none;
        overflow-y: hidden;
        left: 335px; top: 11px; height: 1300px; width: 826px;
        box-shadow: 0 0 20px 3px rgba(90, 90, 90, 0.363);

        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);

        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
}
.resume > a{
    margin:20px;
}