
.App {
  text-align: center;
  width: 100vw;
  /* height: 200vh; */
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory; 
  /* overflow hidden here breaks sticky footer */
  overflow-x: clip;
}

/* width */
::-webkit-scrollbar {
  width: 0.8vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e7e7e7;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a4d5ffaa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a4d5ff;
}

@font-face {
  font-family: "Comfortaa";
  font-size: large;
  src: local("Comfortaa"),
    url("./assets/fonts/Comfortaa-VariableFont_wght.ttf") format("truetype")
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
} */

