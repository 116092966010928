/* KNOWN ISSUES */

/*  1. mouse mimic (#moustest) along with client.Y doesn't account for page scrolling, maybe cursor distance from the screenshot instead?
    2. any screenshot solution would need to account for page resizing
    3. iframe instead of screenshot?
*/

.projects{
    display: flex;
    flex-direction: column;
    color: black;
    height: 100%;
    /* width: 100%; */
    margin-left:10%;
    margin-right:10%;
    font-size:large;
    font-family: Comfortaa;
    
    /* Slide Functionality
    position:absolute;
    left: 0px;
    top: 0px; */
}

@media screen and (min-width: 0px) {
    .projects .ant-card-cover img{
        width: 200px;
        height: 200px;
        object-fit:cover;
        border-radius: 50%;
    }
    .projects .ant-card-cover {
        width:  200px;
        height: 200px;
        box-shadow: 0 0 20px 5px rgba(128, 128, 128, 0.39);
        border-radius: 50%;
        display: flex;
    }
    .projects .ant-card{
        font-family: Comfortaa;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:20px;
        margin-bottom:50px;
        width: 250px; /* if cards aren't same size, it messes with centering */
        aspect-ratio: 25/38;
        height: auto;
        /* min-height: 350px; */
        transition: transform 400ms ease,
                    box-shadow 0.2s, 
                    border-color 0.2s, 
                    width 400ms ease,
                    height 400ms ease;
    }
    .art-icon-wrapper img:nth-child(2){
        width:  198px;
        height: 198px;
        border-left: 5px;
    }
    .art-icon-wrapper img:nth-child(3){
        width:  198px;
        height: 198px;
    }
}

@media screen and (min-width: 1251px) {
    .projects .ant-card-cover img{
        width: 150px;
        height: 150px;
        object-fit:cover;
        border-radius: 50%;
    }
    .projects .ant-card-cover {
        width:  150px;
        height: 150px;
        box-shadow: 0 0 20px 5px rgba(128, 128, 128, 0.39);
        border-radius: 50%;
        display: flex;
    }
    .projects .ant-card{
        font-family: Comfortaa;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:20px;
        margin-bottom:50px;
        width: 200px; /* if cards aren't same size, it messes with centering */
        aspect-ratio: 25/38;
        height: auto;
        /* min-height: 350px; */
        transition: transform 400ms ease,
                    box-shadow 0.2s, 
                    border-color 0.2s, 
                    width 400ms ease,
                    height 400ms ease;
    }
    .art-icon-wrapper img:nth-child(2){
        width:  148px;
        height: 148px;
        border-left: 5px;
    }
    .art-icon-wrapper img:nth-child(3){
        width:  148px;
        height: 148px;
    }
}

@media screen and (min-width: 1563px) {
    .projects .ant-card-cover img{
        width: 200px;
        height: 200px;
        object-fit:cover;
        border-radius: 50%;
    }
    .projects .ant-card-cover {
        width:  200px;
        height: 200px;
        box-shadow: 0 0 20px 5px rgba(128, 128, 128, 0.39);
        border-radius: 50%;
        display: flex;
    }
    .projects .ant-card{
        font-family: Comfortaa;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:20px;
        margin-bottom:50px;
        width: 250px; /* if cards aren't same size, it messes with centering */
        aspect-ratio: 25/38;
        height: auto;
        /* min-height: 350px; */
        transition: transform 400ms ease,
                    box-shadow 0.2s, 
                    border-color 0.2s, 
                    width 400ms ease,
                    height 400ms ease;
    }
    .art-icon-wrapper img:nth-child(2){
        width:  198px;
        height: 198px;
        border-left: 5px;
    }
    .art-icon-wrapper img:nth-child(3){
        width:  198px;
        height: 198px;
    }
}

.projects-wrapper{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 50px;
    position: relative;
}

/* .center-line{
    background-color: black;
    width: 2px;
    height: 100%;
    z-index:1000;
    position:absolute;
    padding-left:50%-1px;
} */



/* .projects .ant-card-head{
    background-color:#0088FF;
    color:white;
} */

.projects .ant-card-body{
    display:flex;
    flex-direction: column;
    justify-content: center;
    /* height:100%; /* uncommented: aligned with each other | commented: centered based on height */
}


.projects .ant-card-cover>* {
    display: flex;
}


#ZombeeLogoImg{
    /* width:180px; */
    object-fit: contain;
}

#mousetest{
    width: 10px;
    height:15px;
    position:absolute;
    border-radius: 0%;
}

.coverWrapper{ /* for fake website */
    height:100%;
    width:100%;
    position:relative;
}


.art-icon-wrapper{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    
}
.art-icon-wrapper img{
    position: absolute; /*absolute means % height/width doesn't work */
    display: flex;
    align-items: center;
    /* height:200px; /* decided to have it all in .ant-card-cover img instead
    width:200px; */
    transition: transform 400ms ease;
}

/* .art-icon-wrapper img:nth-child(1){
    visibility: hidden;
} */


.ant-card:hover .art-icon-wrapper>img:nth-child(1){
    transform: translate(-5%);
}
.ant-card:hover img:nth-child(2){
    transform: translate(5%) rotate(5deg) scale(101%);
}
.ant-card:hover img:nth-child(3){
    transform: translate(10%) rotate(10deg) scale(101%);
}

.ant-card:hover {
    transform: translate(0, -3%);
}

/* MODALS */
/* .ant-modal{ */
    /* width: 900px !important; */
/* } */

.ant-modal-title{
    padding-bottom: 40px;
}

.ant-modal-content{
    font-family: "Comfortaa";
    align-items: center;
    text-align: center;
}

.ant-modal-content p{
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
}

#maxBotImage{
    box-shadow: 0 0 20px 5px rgba(90, 90, 90, 0.548);
    max-width: 90%;
}
.ant-modal-content .ChompItTower{
    border-radius: 50%;
    /* box-shadow:none; */
}
.ant-modal-content video{
    box-shadow: 0 0 20px 5px rgba(90, 90, 90, 0.548);
}
#ZBDFootage{
    max-width: 90%;
}
.ant-btn{
    font-family:"Comfortaa"
}

/* Art Portfolio */

.gallery{
    display: flex;
    padding: 2px;
    transition: 0.3s;
    overflow-y: scroll;
    height:800px;
}

.galleryColumn{
    display: flex;
    flex-direction: column;
    width:50%;
}

.galleryImageContainer{
    position: relative;
    height:fit-content;
}

.gallery img {
    /* display: block; */
    width:100%;
    transition: 0.3s;
    box-shadow: none;

}

.galleryImageContainer:hover .galleryCaption{
    opacity: 1;
}

.galleryCaption{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px 15px 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%; 
    /* 93 should be 100 */
    font-size: 16px;
    color: white;
    opacity: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(255, 255 ,255 , 0) 100%);
    transition: .3s;
}

.galleryGroup{
    display: none;
}

.ant-image .ant-image-mask{
    display: none;
}

.galleryHover{
    position:absolute;
    top: 0px;
    left: 0px;
}

#seriesHover:hover #main{
    animation-name: seriesHoverAnim;
    animation-duration: 6s;
    animation-iteration-count: infinite;
}

#seriesHover:hover #hover1{
    animation-name: seriesHoverAnim;
    animation-duration: 6s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
}
#seriesHover:hover #hover2{
    animation-name: seriesHoverAnim;
    animation-duration: 6s;
    animation-delay: 4s;
    animation-iteration-count: infinite;
}

@keyframes seriesHoverAnim {
    0%{
        opacity: 1;
    }
    25%{
        opacity: 1;
    }
    33%{
        opacity: 0;
    }
    83%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}